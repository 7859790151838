/* General styles for the application form */
.application-form {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
 font-size: 36px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  z-index: 2;
}

.react-select__menu,
.react-select_phone__menu {
  position: absolute;
  z-index: 1000 !important;
}


input[type="text"],
input[type="email"],
input[type="tel"],
select,
.react-select_phone__control,
.styled-masked-input,
.react-datepicker-wrapper {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.react-datepicker-popper {
      z-index: 10 !important
}

.react-select_phone__control {
    padding: 2px;
}

.react-datepicker-wrapper {
    display: block;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
}

.react-select__control {
  position: relative;
  z-index: 1;
  min-height: 46px;
  height: 46px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: none;
}

.react-select__value-container {
  padding: 0 2px;
}

.react-select__input-container {
  margin: 0;
  padding: 0;
}

.react-select__single-value {
  color: #333;
}

.react-select__placeholder {
  color: #999;
}

.form-group .react-select__label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Ensure react-select components have the same width as other inputs */
.react-select {
  width: 100%;
}

.country-option {
  display: flex;
  align-items: center;
}

.country-flag {
    font-size: 1.5em;
    margin-right: 12px;
    font-family: "Segoe UI Emoji", "Apple Color Emoji", "Noto Color Emoji", "Segoe UI Symbol", "Symbol";
}

.phone-input-container {
  display: flex;
  gap: 10px;
  align-items: stretch;
}

.phone-input-container .react-select__control {
  font-size: 16px;
}

.phone-input-container .react-select__single-value,
.phone-input-container .react-select__option {
  display: flex;
  align-items: center;
  padding: 10px;
}

.phone-input-container input[type="tel"] {
  flex: 1;
  font-size: 16px;
  padding: 10px;
}

.linkedin-input {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
}

.linkedin-prefix {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #fdfdfd;
    font-size: 14px;
}

.linkedin-prefix-left {
  padding-right: 0px;
  padding-left: 0px;
}

.linkedin-prefix-right {
  padding-left: 0px;
}

.linkedin-prefix-icon {
  padding-right: 10px;
  padding-left: 10px;
}

.linkedin-input input {
    flex-grow: 1;
    border: none;
    padding: 10px;
    font-size: 16px;
}

.radio-group {
  display: flex;
  gap: 20px;
}

form .radio-group,
form .checkbox-group {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: start;
    flex-direction: column;
    padding-top: 25px;
    padding-left: 25px;
}

.radio-group label {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.radio-group input[type="radio"] {
  margin-right: 5px;
}

.photo-drop-zone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.photo-preview {
  margin-top: 10px;
}

.photo-preview img {
  max-width: 200px;
  max-height: 200px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="button"] {
  background-color: #6c757d;
}

/* Theme-specific overrides will be applied from the imported CSS files */

.photo-drop-zone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;
}

.photo-drop-zone:hover,
.photo-drop-zone:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.choose-file-button,
.form-actions button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

.photo-preview {
    margin-top: 10px;
}

.photo-preview img {
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
}

.photo-drop-zone.error {
    border-color: #ff0000;
}

.error-message {
    color: #ff0000;
    font-size: 14px;
    margin-top: 5px;
}

.application-form-container {
    position: relative;
    width: 100%;
    max-width: 700px; /* Increased from 600px */
    margin: 0 auto;
    padding: 20px;
    padding-top: 150px; /* Increased to accommodate centered logo */
    box-sizing: border-box;
}

.company-logo-container {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.company-logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
    .company-logo-container {
        width: 100px;
        height: 60px;
    }
}

.application-form {
    padding-top: 20px; /* Reduced from 200px since we moved padding to container */
}

/* Responsive design */
@media (max-width: 600px) {
  .application-form {
    padding: 10px;
  }

  .form-actions {
    flex-direction: column;
    gap: 10px;
  }

  button {
    width: 100%;
  }

  .application-form-container  {
    padding: 0;
  }

  .application-form {
    padding: 40px;
  }

}

@media (max-width: 420px) { 
    .application-form {
    padding: 15px;
  }

  .linkedin-prefix-left { 
    display: none;
  }
}

div.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.react-datepicker__year-dropdown.react-datepicker__year-dropdown--scrollable {
  height: 200px;
}

.react-datepicker__year-option {
  line-height: 40px;
}

.react-datepicker {
  font-size: 1rem;
}

#phoneCode {
  min-width: 120px;
}
