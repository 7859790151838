.application-success {
    max-width: 800px;
    margin: 40px auto;
    padding: 40px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.application-success header {
    margin-bottom: 30px;
}

.application-success .company-logo {
    max-width: 200px;
    margin-bottom: 20px;
}

.application-success h1 {
    color: #4285f4;
    font-size: 32px;
    margin-bottom: 20px;
}

.application-success p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 30px;
}

.application-success .return-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4285f4;
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.application-success .return-link:hover {
    background-color: #3367d6;
}

/* Responsive design */
@media (max-width: 600px) {
    .application-success {
        padding: 20px;
        box-shadow: unset;
    }

    .application-success h1 {
        font-size: 24px;
    }

    .application-success p {
        font-size: 16px;
    }
}