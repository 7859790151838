@import './styles/ApplicationForm.css';

/* Add any global styles here */
body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.application-form {
  margin-top: 40px;
  margin-bottom: 40px;
}

/* Add any additional global styles as needed */